<template>
  <v-card :height="cardHeight">
    <v-card-title>Billable hours by month</v-card-title>
    <v-row justify="center">
      <v-spacer />
      <v-col cols="10">
        <GChart
          :settings="{ packages: ['line'] }"
          :data="chartData"
          :options="chartOptions"
          :createChart="(el, google) => new google.charts.Line(el)"
          @ready="onChartReady"
        />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row justify="center">
      <v-spacer />
      <v-col cols="10">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <!-- TO ensure that the table start with a month and not the heading  -->
                <template v-for="item in chartData.slice(1)">
                  <th class="text-left" :key="item[0]">{{ item[0] }}</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr key="Hours">
                <template v-for="item in chartData.slice(1)">
                  <td :key="item[0]">{{ item[1] }}</td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-spacer />
    </v-row>
    <v-card-text> </v-card-text>
  </v-card>
</template>

<script>
import { GChart } from "vue-google-charts";
export default {
  name: "YearBillableHoursCard",
  components: { GChart },
  props: {
    cardHeight: String,
    year: String
  },
  data: () => ({
    chartsLib: null,
    tableData: [450, 400, 0],
  }),
  computed: {
    chartOptions() {
      if (!this.chartsLib) return null;
      return this.chartsLib.charts.Line.convertOptions({
        chart: {
          title: "Billable Hours",
          // subtitle: "A Subtitle",
        },
        colors: ["#64a48c"],
      });
    },
    chartData() {
      let tr = this.$store.state.timeReports.timeReports;
      let chartData = [
        ["Month", "Hours"],
        ["Jan.", 0],
        ["Feb.", 0],
        ["Mar.", 0],
        ["Apr.", 0],
        ["May", 0],
        ["June", 0],
        ["July", 0],
        ["Aug.", 0],
        ["Sep.", 0],
        ["Oct.", 0],
        ["Nov.", 0],
        ["Dec.", 0],
      ];

      let getHours = function (timeReport, y) {
        if (
          timeReport.type === "Normal" &&
          timeReport.month.split("-")[0] == y
        ) {
          // chartData[month][1] = chartData[month][1] + parseInt(tr[t].hours, 10);
          return parseInt(timeReport.hours, 10);
        } else {
          return 0;
        }
      };
      for (var t in tr) {
        let monthInt = parseInt(tr[t].month.split("-")[1]);
        chartData[monthInt][1] = chartData[monthInt][1] + getHours(tr[t], this.year);
      }
      return chartData;
    },
  },
  methods: {
    onChartReady(chart, google) {
      this.chartsLib = google;
    },
  },
};
</script>